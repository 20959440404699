import React from 'react';
import styled from 'styled-components';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import {useOutlet} from 'reconnect.js';
import * as Ant from 'antd';
import * as AppActions from '../../AppActions';

export default function SelectDemoTimeModal(props) {
  const {visible, setVisible, ...restProps} = props;
  return (
    <Ant.Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={480}
      visible={visible}
      onOk={() => {
        setVisible(false);
      }}
      onCancel={() => {
        setVisible(false);
      }}
      destroyOnClose={true}>
      <ModalContent {...restProps} />
    </Ant.Modal>
  );
}

function ModalContent(props) {
  const {freeSlots, selectTimeSlot} = props;
  const [selectedSlot, setSelectedSlot] = React.useState(freeSlots[0]);

  return (
    <Wrapper>
      <h2>選擇時段</h2>
      <h3>日期: {freeSlots[0].date}</h3>
      <div>
        <Ant.Select
          style={{width: 200}}
          value={selectedSlot?.id}
          onChange={setSelectedSlot}>
          {freeSlots.map((slot, idx) => {
            return (
              <Ant.Select.Option key={idx} style={{width: 200}} value={slot.id}>
                {slot.time}
              </Ant.Select.Option>
            );
          })}
        </Ant.Select>
      </div>
      <Ant.Button
        type="primary"
        size="large"
        style={{alignSelf: 'flex-end'}}
        onClick={() => {
          selectTimeSlot(selectedSlot);
        }}>
        確認
      </Ant.Button>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
`;
