import React from 'react';
import styled from 'styled-components';
import * as Ant from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import Calendar from 'rev.sdk.js/Components/Calendar';
import {DT} from 'rev.sdk.js/Components/Calendar/CalendarUtil';
import * as AppActions from '../../AppActions';
import SelectDemoTimeModal from './SelectDemoTimeModal';
import Config from '../../../data.json';

function DateCell(props) {
  const {year, month, date, slots, classNames: _classNames, onClick} = props;
  const curr = DT({year, month, date});

  let freeSlots = [];
  let classNames = [];

  if (curr.getTime() - Date.now() > 2 * 7 * 24 * 3600000) {
    classNames.push('disabled');
  } else if (Date.now() - curr.getTime() > 24 * 3600000) {
    classNames.push('disabled');
  } else {
    for (const slot of slots) {
      const [y, m, d] = slot.date.split('-');
      if (
        curr.getFullYear() === parseInt(y) &&
        curr.getMonth() + 1 === parseInt(m) &&
        curr.getDate() === parseInt(d) &&
        !slot.customer_email
      ) {
        freeSlots.push(slot);
      }
    }

    if (freeSlots.length === 0) {
      classNames.push('disabled');
    }
  }

  return (
    <DateCellWrapper
      className={_classNames.concat(classNames).join(' ')}
      onClick={(e) => {
        if (!classNames.includes('disabled') && freeSlots.length > 0) {
          onClick(e, {freeSlots});
        }
      }}>
      {date}
      {freeSlots.length > 0 && (
        <div style={{fontSize: 7}}>剩{freeSlots.length}時段</div>
      )}
    </DateCellWrapper>
  );
}

const DateCellWrapper = styled.div`
  position: relative;
  /* here to custom*/
  &.disabled::after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
  &.r-cell.r-date.r-today {
    background-color: pink;
    border: solid 1px #1096cb;
  }
  &.r-today::after {
    content: '';
  }
`;

export default function ScheduleDemoPage(props) {
  const [timeSlots, setTimeSlots] = React.useState([]);
  const [freeSlots, setFreeSlots] = React.useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = React.useState(null);
  const [values, setValues] = React.useState({
    name: '',
    email: '',
    note: '',
  });

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        const zeroPadding = (str) => ('00' + str).slice(-2);
        const startDate = new Date();
        const endDate = new Date(startDate.getTime() + 2 * 7 * 24 * 3600000);
        const slots = await JStorage.fetchDocuments(
          'demo_time_slot',
          {
            date: {
              $gte: `${startDate.getFullYear()}-${zeroPadding(
                startDate.getMonth() + 1,
              )}-${zeroPadding(startDate.getDate())}`,
              $lte: `${endDate.getFullYear()}-${zeroPadding(
                endDate.getMonth() + 1,
              )}-${zeroPadding(endDate.getDate())}`,
            },
          },
          null,
          null,
          null,
          {anonymous: true},
        );
        setTimeSlots(slots);
      } catch (ex) {
        console.warn('ScheduleDemoPage ex', ex);
      }
      AppActions.setLoading(false);
    }

    fetchData();
  }, []);

  const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
  const canSubmit =
    values.name &&
    values.email &&
    values.note &&
    selectedTimeSlot &&
    emailPattern.test(values.email);

  async function submit() {
    if (!canSubmit) {
      alert('尚有欄位未填寫');
      return;
    }

    AppActions.setLoading(true);
    try {
      await ApiUtil.req(`${Config.apiHost}/mkt/demo-request`, {
        method: 'POST',
        data: {
          slot_id: selectedTimeSlot.id,
          email: values.email,
          name: values.name,
          note: values.note,
        },
      });
      alert('預約成功!\n稍後請檢查您的電子信箱, 我們會將邀請信函寄給您.');
      AppActions.navigate('/');
    } catch (ex) {
      alert('預約未成功!\n您所選的時段可能被選走了, 請重整頁面再重新嘗試一次');
      console.warn('ScheduleDemoPage ex', ex);
    }
    AppActions.setLoading(false);
  }

  return (
    <Wrapper>
      <div className="content">
        <h2 className="title">預約專家說明</h2>
        <div className="hint">
          由專人替您介紹本系統, 完全免費, 由專人替您介紹本系統, 完全免費!
        </div>

        <div className="grid">
          <div className="calendar">
            <Calendar
              onSelect={({year, month, date, dt}) => {
                console.log('selected', `${year}-${month}-${date}`, dt);
              }}
              renderDate={(props) => {
                return (
                  <DateCell
                    key={props.date}
                    {...props}
                    slots={timeSlots}
                    onClick={(e, {freeSlots}) => {
                      setFreeSlots(freeSlots);
                    }}
                  />
                );
              }}
            />
            <div style={{color: '#aaa'}}>* 系統目前僅開放預約2週內時段</div>
          </div>

          <div className="form">
            <div className="field">
              <label>預約日期</label>
              <div>{selectedTimeSlot?.date || '---'}</div>
            </div>

            <div className="field">
              <label>預約時段</label>
              <div>{selectedTimeSlot?.time || '---'}</div>
            </div>

            <div className="field">
              <label>您的大名 *</label>
              <Ant.Input
                type="name"
                value={values.name}
                onChange={(e) => setValues({...values, name: e.target.value})}
              />
            </div>

            <div className="field">
              <label>您的信箱 *</label>
              <Ant.Input
                type="email"
                value={values.email}
                onChange={(e) => setValues({...values, email: e.target.value})}
              />
              <div className="hint">* 預約後我們將寄送聯絡資訊至您的信箱</div>
            </div>

            <div className="field">
              <label>您的需求 *</label>
              <Ant.Input.TextArea
                value={values.note}
                onChange={(e) => setValues({...values, note: e.target.value})}
                style={{height: 150}}
              />
              <div className="hint">* 我們可以提前準備您所需要的資訊</div>
            </div>

            <Ant.Button
              size="large"
              type="primary"
              disabled={!canSubmit}
              style={{width: '100%', display: 'block'}}
              onClick={submit}>
              確認送出
            </Ant.Button>
          </div>
        </div>
      </div>

      <SelectDemoTimeModal
        visible={freeSlots?.length > 0}
        setVisible={setFreeSlots}
        freeSlots={freeSlots}
        selectTimeSlot={(slot) => {
          if (slot) {
            setSelectedTimeSlot(slot);
          }
          setFreeSlots(null);
        }}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  h2,
  h3,
  h4 {
    margin: 0;
  }

  padding-top: 84px;
  min-height: calc(100vh - 400px);
  background-color: #eee;
  padding: 100px 20px;

  & > .content {
    max-width: 1024px;
    margin: 0 auto;
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.33);

    & > h2.title {
      text-align: center;
      font-size: 30px;
    }

    & > .hint {
      color: #888;
      max-width: 300px;
      margin: 0 auto;
      margin-bottom: 20px;
    }

    & > .grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
      grid-gap: 3vw;

      & > * {
        &.calendar {
          min-height: 300px;
          border-radius: 20px;
        }

        & .field {
          margin-bottom: 20px;
          & > * {
            display: block;
            &.hint {
              color: #888;
            }
          }
        }
      }
    }
  }
`;
